import React, { ForwardedRef, forwardRef, memo, useState } from 'react';
import styled, { css } from 'styled-components';

import { useInView } from 'react-intersection-observer';

import { useWindowSize } from 'react-use';
import { useMediaQuery } from 'usehooks-ts';
import dynamic from 'next/dynamic';
import type {
  BlockMediaCollectionFragment,
  MediaItemFragment,
} from '../schemas/api';

import TYPO from '../styles/typography';
import { Grid, columns } from '../styles/grid';

import MarkdownInline from './MarkdownInline';
import CTAButtons from './CTAButtons';
import COLORS from '../styles/colors';

import Heading from './Heading';
import Image from './Image';
import PlayButton from './PlayButton';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

const ModalVideo = dynamic(() => import('./ModalVideo'), { ssr: false });

const DESCRIPTION_HEIGHT = 86;
const DESCRIPTION_HEIGHT_MOBILE = 60;
const TOP_PADDING = 36;
const ROW_SPACING = 40;
const TRANSLATE_Y = 80;
const COLUMNS = 18;
const MAX_WIDTH = 1440;

const TRANSITION = '0.7s cubic-bezier(0.72, 0, 0.12, 1) 0s';

const Container = styled(Grid)`
  padding: 52px 12px 6px;

  @media (min-width: 768px) {
    padding: 52px 12px 40px;
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 24px;
`;

type HeadingSectionProps = {
  $inView: boolean;
};
const HeadingSection = styled.div<HeadingSectionProps>`
  padding: 0 12px;
  ${columns(1, 12)};

  @media (min-width: 768px) {
    ${columns(3, 8)};
  }

  display: flex;
  flex-direction: column;

  transform-origin: bottom center;

  transform: ${(props) =>
    props.$inView ? `translateY(15%) scale(0.95)` : `translateY(0%) scale(1)`};
  transition: transform ${TRANSITION};
`;

const Items = styled.div`
  aspect-ratio: 1 / 1;
  transition: all ${TRANSITION};
  position: relative;
  width: 100%;
  will-change: height, aspect-ratio, transform;

  max-height: ${MAX_WIDTH / (16 / 9)}px;

  @media (min-width: 1110px) {
    aspect-ratio: 2 / 1;
  }

  @media (min-width: 768px) {
    aspect-ratio: 17 / 10;
  }
`;

type BackgroundImageProps = {
  $active: boolean;
};

const BackgroundImage = styled(Image)<BackgroundImageProps>`
  object-position: center top;
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  grid-column: 2 / ${COLUMNS};

  ${(props) => {
    if (props.$active) {
      return css`
        opacity: 1;
        visibility: visible;
        scale: 1;
        transition: opacity 0.3s ease 0s, scale 0.3s ease-in-out 0s;
      `;
    }
    return css`
      opacity: 0;
      visibility: hidden;
      scale: 1.1;
      transition: opacity 0.3s ease 0s, scale 0.3s ease-in-out 0s,
        visibility 1ms ease 0.3s;
    `;
  }}
`;

const Backgrounds = styled.div`
  background: ${COLORS.black.css};
  grid-template-columns: repeat(${COLUMNS}, 1fr);
  display: grid;
  margin: 0px auto;
  position: relative;
  width: 100%;
  height: 100%;

  transition: all ${TRANSITION};
  overflow: hidden;
  opacity: 1;
  will-change: scale, width;

  @media (min-width: 1440px) {
    width: 100%;
  }

  @media (min-width: 768px) {
    scale: 1.2375;
  }
`;

const Videos = styled.div`
  grid-template-columns: repeat(${COLUMNS}, 1fr);
  display: grid;
  max-width: ${MAX_WIDTH}px;
  margin: 0px auto;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 50%;
  transform-origin: center top;

  width: 100%;
  transition: transform ${TRANSITION};
`;

const Media = styled.div`
  border: 2px solid ${COLORS.brand.light.css};
  background: ${COLORS.white.css};
  border-radius: 9px;
  transition: box-shadow ${TRANSITION};
  overflow: hidden;
  position: relative;
  height: 100%;
  aspect-ratio: 16 / 9;
  display: block;

  & > div {
    object-fit: cover;
    display: block;
    position: relative;
    width: 100%;
    pointer-events: none;
    z-index: 2;

    & video {
      object-position: top;
      object-fit: cover;
      scale: 1.001;
    }
  }
`;

type MediaItemProps = {
  $active: boolean;
};

const MediaItem = styled.div<MediaItemProps>`
  margin: 0px auto;

  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: opacity 0.3s ease 0s, transform 0.3s ease-in-out 0s;
  pointer-events: all;
  grid-column: 1 / -1;
  width: calc(100% - 32px);

  ${(props) => {
    if (props.$active) {
      return css`
        opacity: 1;
        visibility: visible;
        transform: scale(1);
        transition: opacity 0.3s ease 0s, transform 0.3s ease-in-out 0s;
      `;
    }
    return css`
      opacity: 0;
      visibility: hidden;
      transform: scale(0.95);
      transition: opacity 0.3s ease 0s, transform 0.3s ease-in-out 0s,
        visibility 1ms ease 0.3s;
    `;
  }}

  @media (min-width: 1130px) {
    left: 24px;
    width: calc(100% - 48px);
  }

  @media (min-width: 768px) {
    left: 16px;
    grid-column: 2 / 18;
    margin: 0px;
    position: absolute;
  }
`;

const PlaceholderImage = styled(Image)`
  object-position: top;
  display: block;
  object-fit: cover;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
`;

const VideoContainer = styled.div`
  max-width: 100%;
  margin: 0px auto;
  width: 100%;
  position: relative;

  @media (min-width: 1130px) {
    max-width: calc(100% - 352px);
  }

  @media (min-width: 768px) {
    max-width: calc(100% - 128px);
  }
`;

const ItemHeading = styled.div`
  color: white;
  display: block;

  margin-bottom: 0px;
  text-align: center;
  text-wrap: balance;
  transform: translateY(-4px);
  max-width: 90%;

  color: white;
  font-size: 18px;
  line-height: 140%;
  font-weight: 600;
  letter-spacing: -0.03em;
`;

const ItemLabel = styled.div`
  color: white;
  display: block;

  margin-bottom: 0px;
  text-align: center;
  text-wrap: balance;
  transform: translateY(-4px);
  max-width: 100%;

  color: white;
  font-size: 14px;
  line-height: 140%;
  font-weight: 400;
  letter-spacing: -0.03em;

  @media (min-width: 1130px) {
    font-size: 21px;
  }

  @media (min-width: 768px) {
    max-width: 90%;
    font-weight: 500;
    font-size: 16px;
  }

  @media (min-width: 480px) {
    font-size: 15px;
  }
`;

const ItemContent = styled.div`
  grid-template-columns: repeat(${COLUMNS}, 1fr);
  display: grid;
  max-width: ${MAX_WIDTH}px;
  margin: 0px auto;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, 0);
`;

const ItemInner = styled.div`
  padding: 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: 1130px) {
    padding: 36px 36px 48px;
    gap: 24px;
  }

  @media (min-width: 768px) {
    padding: 32px 32px 48px;
    gap: 24px;
    grid-column: 2 / ${COLUMNS};
    justify-content: flex-end;
  }

  @media (min-width: 480px) {
    padding: 30px;
  }

  flex-wrap: wrap;
  gap: 9px;
  width: 100%;
  position: relative;
  transition: opacity ${TRANSITION};
  grid-column: 1 / -1;
`;

type CarouselContainerProps = {
  $inView: boolean;
  $colSize: number;
};
const CarouselContainer = styled.section<CarouselContainerProps>`
  ${columns(1, 12)}
  margin-top: 84px;
  position: relative;
  display: block;
  flex: 1 0 100%;

  scroll-snap-align: center;

    ${(props) => {
      if (props.$inView) {
        return css`
          ${Items} {
            @media (min-width: 768px) {
              clip-path: inset(0px ${Math.floor(props.$colSize)}px round 12px);
            }
            clip-path: inset(0px 0px round 6px);
            transform: translateY(0px);
          }
          ${Backgrounds} {
            scale: 1.3;
          }
          ${Videos} {
            transform: translate(
              -50%,
              ${TOP_PADDING + DESCRIPTION_HEIGHT_MOBILE + ROW_SPACING}px
            );
            @media (min-width: 480px) {
              transform: translate(
                -50%,
                ${TOP_PADDING + DESCRIPTION_HEIGHT + ROW_SPACING}px
              );
            }
            @media (min-width: 768px) {
              transform: translate(-50%, ${TOP_PADDING}px);
            }
          }
          ${Media} {
            box-shadow: 0px 0px 10px ${COLORS.brand.regular.opacity(0.1)};
          }
          ${ItemInner} {
            opacity: 1;
          }
        `;
      }
      return css`
        ${Items} {
          @media (min-width: 768px) {
            clip-path: inset(0% 0px);
          }
          clip-path: inset(0px 0px round 6px);
          transform: translateY(70px);
        }
        ${Backgrounds} {
          scale: 1.2;
        }
        ${Videos} {
          transform: translate(-50%, 0px) scale(0.95);
          @media (min-width: 768px) {
            transform: translate(-50%, -${TRANSLATE_Y}px) scale(1.15);
          }
        }
        ${Media} {
          box-shadow: 0px 0px 48px ${COLORS.brand.regular.opacity(0.1)};
          @media (min-width: 768px) {
            box-shadow: 0px 0px 80px ${COLORS.brand.regular.opacity(0.1)};
          }
        }
        ${ItemInner} {
          opacity: 0;
        }
      `;
    }}
  }
`;

type MobileCarouselContainerProps = {
  $inView: boolean;
};

const MobileCarouselContainer = styled.div<MobileCarouselContainerProps>`
  ${columns(1, 12)}
  scroll-snap-type: x mandatory;
  display: flex;
  overflow: auto hidden;

  padding: 48px 12px 0;
  ${CarouselContainer} {
    flex: 0 0 calc(100% - 60px);
    margin: 0 12px;
    transition: all 0.2s ease;
  }
`;

const Navbar = styled.nav`
  display: flex;
  gap: 3px;
  border-radius: 6px;
  height: 48px;
  border: 1px solid white;
  padding: 3px 3px;
`;

type NavItemProps = {
  $active: boolean;
};

const NavItem = styled.button<NavItemProps>`
  ${TYPO.p1};
  font-weight: 500;
  letter-spacing: 0.01em;
  background: ${(props) => (props.$active ? '#fff' : 'transparent')};
  color: ${(props) => (props.$active ? '#000' : '#fff')};
  border-radius: 3px;
  padding: 3px 24px;
  &:hover {
    background: ${(props) =>
      props.$active ? '#fff' : 'rgba(255,255,255,0.1)'};
  }
`;

type DisplayVideoItemProps = React.HTMLAttributes<HTMLDivElement> & {
  data: MediaItemFragment;
  active: boolean;
  demoVideo?: string;
};

function DisplayVideoItem({
  data,
  active,
  demoVideo,
  ...props
}: DisplayVideoItemProps) {
  const [show, setShow] = useState(false);

  return (
    <MediaItem {...props} $active={active}>
      {demoVideo && (
        <ModalVideo
          open={show}
          setOpen={setShow}
          url={demoVideo}
          width="100%"
        />
      )}
      <VideoContainer>
        {demoVideo && <PlayButton onClick={() => setShow(true)} />}
        <Media>
          <ReactPlayer
            data-video-container
            url={`${data.media.url}#t=0.5`}
            width="100%"
            height="100%"
            playing={active}
            muted
            loop
            controls={false}
            config={{ file: { attributes: { poster: data.posterImage?.url } } }}
          />
          {data.posterImage && (
            <PlaceholderImage
              src={data.posterImage.url}
              width={data.posterImage.width}
              height={data.posterImage.height}
              placeholder="blur"
              priority={active}
              alt={`placeholder ${data.heading}`}
            />
          )}
        </Media>
      </VideoContainer>
    </MediaItem>
  );
}

type CarouselProps = {
  items: MediaItemFragment[];
  inView: boolean;
  demoVideo?: string;
};

const Carousel = forwardRef(
  (
    { items, inView, demoVideo }: CarouselProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const size = useWindowSize();
    const [active, setActive] = useState(0);

    return (
      <CarouselContainer
        ref={ref}
        $inView={inView}
        $colSize={
          (size.width > MAX_WIDTH ? (size.width - MAX_WIDTH) / 2 : 0) +
          Math.min(MAX_WIDTH, size.width) / COLUMNS
        }
      >
        <Items>
          <Backgrounds>
            {items.map((d, i) => {
              if (!d.background) return null;
              return (
                <BackgroundImage
                  alt={d.background.title || d.heading}
                  key={d.sys.id}
                  src={d.background.url}
                  width={d.background.width}
                  height={d.background.height}
                  $active={active === i}
                  priority={active === i}
                  aria-current={active === i}
                />
              );
            })}
          </Backgrounds>
          <ItemContent>
            <ItemInner role="region" aria-roledescription="carousel">
              {items.map((d, i) => {
                return (
                  <ItemLabel
                    key={d.sys.id}
                    style={{ display: active === i ? 'block' : 'none' }}
                    aria-current={active === i}
                    role="group"
                    aria-roledescription="Slide"
                  >
                    <MarkdownInline>{d.content}</MarkdownInline>
                  </ItemLabel>
                );
              })}
              <Navbar>
                {items.map((d, i) => {
                  return (
                    <NavItem
                      key={d.sys.id}
                      $active={active === i}
                      onClick={() => setActive(i)}
                      aria-current={active === i}
                    >
                      {d.heading}
                    </NavItem>
                  );
                })}
              </Navbar>
            </ItemInner>
          </ItemContent>
        </Items>
        <Videos role="region" aria-roledescription="carousel">
          {items.map((d, i) => {
            return (
              <DisplayVideoItem
                data={d}
                key={d.sys.id}
                active={active === i}
                demoVideo={demoVideo}
                role="group"
                aria-roledescription="Slide"
                aria-current={active === i}
              />
            );
          })}
        </Videos>
      </CarouselContainer>
    );
  }
);

Carousel.displayName = 'Carousel';

type MobileSlideProps = {
  data: MediaItemFragment;
  sectionInView: boolean;
  demoVideo?: string;
};
const MobileSlide = ({ data, sectionInView, demoVideo }: MobileSlideProps) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    rootMargin: '0px 30% 30% 30%',
  });

  return (
    <CarouselContainer
      data-in-view={sectionInView}
      data-current-slide={inView}
      $inView={true}
      $colSize={0}
      ref={ref}
    >
      <Items>
        <Backgrounds>
          <BackgroundImage
            alt={data.background.title || data.heading}
            src={data.background.url}
            width={data.background.width}
            height={data.background.height}
            $active
            aria-selected
          />
        </Backgrounds>
        <ItemContent>
          <ItemInner>
            <ItemHeading>{data.heading}</ItemHeading>
            <ItemLabel aria-selected>
              <MarkdownInline>{data.content}</MarkdownInline>
            </ItemLabel>
          </ItemInner>
        </ItemContent>
      </Items>
      <Videos>
        <DisplayVideoItem
          data={data}
          active
          aria-selected
          demoVideo={demoVideo}
        />
      </Videos>
    </CarouselContainer>
  );
};

const MobileCarousel = forwardRef(
  (
    { items, inView, demoVideo }: CarouselProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <MobileCarouselContainer ref={ref} $inView={inView}>
        {items.map((d) => {
          return (
            <MobileSlide
              data={d}
              sectionInView={inView}
              key={d.sys.id}
              demoVideo={demoVideo}
            />
          );
        })}
      </MobileCarouselContainer>
    );
  }
);

MobileCarousel.displayName = 'Carousel';

type BlockTeamCarouselProps = {
  data: BlockMediaCollectionFragment;
};

function BlockZoomCarousel({ data }: BlockTeamCarouselProps) {
  const isDesktop = useMediaQuery('(min-width: 768px)', {
    initializeWithValue: false,
    defaultValue: true,
  });
  const { ref, inView, entry } = useInView({
    rootMargin: '0px 0px -90% 0px',
    threshold: 0.1,
  });

  const itemInView = inView || entry?.boundingClientRect?.y < 0 || false;

  return (
    <React.Fragment>
      <Container ref={ref}>
        <HeadingSection $inView={isDesktop ? itemInView : false}>
          <StyledHeading heading={data.heading} byline={data.byline} show />
          {!!data.actions?.items.length && (
            <CTAButtons links={data.actions.items} />
          )}
        </HeadingSection>
      </Container>
      {isDesktop ? (
        <Carousel
          items={data.mediaCollection.items}
          inView={itemInView}
          demoVideo={data.extra.demo}
          key="desktop"
        />
      ) : (
        <MobileCarousel
          items={data.mediaCollection.items}
          inView={itemInView}
          demoVideo={data.extra.demo}
          key="mobile"
        />
      )}
    </React.Fragment>
  );
}

export default memo(BlockZoomCarousel);
