import React, { memo } from 'react';
import styled from 'styled-components';

import type { BlockMediaCollectionFragment } from '../schemas/api';

import useInview from '../hooks/useInview';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';

import { Grid, columns } from '../styles/grid';

import { spacing } from '../styles/blocks';
import Heading from './Heading';
import Icon, { IconName } from './Icon';
import Markdown from './Markdown';

type ContainerProps = {
  $spacing: string;
};

const Container = styled(Grid)<ContainerProps>`
  ${(props) => spacing(props.$spacing || '')};
`;

type ContentProps = { $show: boolean };
const Content = styled.div<ContentProps>`
  ${columns(1, 12)}

  margin-bottom: 36px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 36px;
  ${(props) => staggerChildrenFadeTranslateIn(props.$show, 0, undefined, 0.05)};

  @media (min-width: 768px) {
    padding: 0;
    ${columns(1, 6)}
  }
  @media (min-width: 1024px) {
    ${columns(1, 5)}
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${COLORS.shades.s200.css};

  padding: 36px;
  box-shadow: 0px 0px 1px ${COLORS.brand.regular.opacity(0.1)},
    0px 4px 100px ${COLORS.brand.regular.opacity(0.1)};
  ${columns(1, 12)}

  @media (min-width: 768px) {
    ${columns(7, 6)}
  }
  @media (min-width: 1024px) {
    ${columns(8, 5)}
  }
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`;

const Item = styled.div`
  display: flex;
  gap: 12px;
  & > svg {
    margin-top: 3px;
    flex: 0 0 auto;
    color: ${COLORS.brand.regular.css};
  }
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const ItemHeading = styled.div`
  ${TYPO.strong};
  font-size: 16px;
`;

const ItemDescription = styled.p`
  ${TYPO.p1};
  margin: 0;
`;

const SideHeading = styled.h2`
  ${TYPO.h2};
`;

const SidePreHeading = styled.h2`
  ${TYPO.strong};
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${COLORS.brand.regular.css};
`;

type MediaCollectionBlockLogosProps = {
  data: BlockMediaCollectionFragment;
};

function MediaCollectionBlockTwoColumnsSide({
  data,
}: MediaCollectionBlockLogosProps) {
  const [inViewRef, inView] = useInview({});

  const item = data.mediaCollection.items[0];
  return (
    <Container $spacing={data.spacing}>
      <Content ref={inViewRef} $show={inView}>
        <Heading
          heading={data.heading}
          byline={data.byline}
          preHeading={data.preHeading}
          show={inView}
          ref={inViewRef}
          align="left"
        />
        {data.description && <Markdown content={data.description} />}
      </Content>
      <List>
        <SidePreHeading>{item.key}</SidePreHeading>
        <SideHeading>{item.heading}</SideHeading>
        <Items>
          {item.items.items.map((d) => {
            return (
              <Item key={d.sys.id}>
                <Icon name={d.icon as IconName} width={16} height={16} />
                <ItemContent>
                  <ItemHeading>{d.heading}</ItemHeading>
                  <ItemDescription>{d.content}</ItemDescription>
                </ItemContent>
              </Item>
            );
          })}
        </Items>
      </List>
    </Container>
  );
}

export default memo(MediaCollectionBlockTwoColumnsSide);
