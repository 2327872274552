import React from 'react';
import styled from 'styled-components';

import COLORS from '../styles/colors';

import Icon from './Icon';

const Container = styled.button`
  z-index: 5;
  position: absolute;

  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
  backdrop-filter: blur(3px);
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);

  background: linear-gradient(
      180deg,
      ${COLORS.white.opacity(0.1)} 17.71%,
      ${COLORS.white.opacity(0.2)} 100%
    ),
    ${COLORS.black.opacity(0.01)};
  border: 1px solid ${COLORS.brand.regular.css};
  border-radius: 50%;

  box-shadow: 0 0 0 8px ${COLORS.white.opacity(0.2)};
  aspect-ratio: 1/1;
  width: 72px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > svg {
    max-width: 33%;
    margin-left: 5%;
    fill: ${COLORS.brand.regular.css};
    stroke: none;
  }

  &:hover {
    width: 84px;
    box-shadow: 0 0 0 4px ${COLORS.white.opacity(0.2)};
    border-color: ${COLORS.brand.regular.css};
  }
`;

function PlayButton(props: React.HTMLAttributes<HTMLButtonElement>) {
  return (
    <Container {...props}>
      <Icon name="Play" width={24} height={24} />
    </Container>
  );
}

export default PlayButton;
