import React, { useState, memo } from 'react';
import styled, { css } from 'styled-components';
import ReactPlayer, { ReactPlayerProps } from 'react-player/lazy';

import { useSpring, animated, easings } from '@react-spring/web';

import useInview from '../hooks/useInview';

import type { BlockSplashVideoFragment } from '../schemas/api';

import { fadeTranslateIn } from '../styles/animations';
import COLORS from '../styles/colors';

import { Grid, columns } from '../styles/grid';
import Icon from './Icon';
import Heading from './Heading';
import CTAButtons from './CTAButtons';
import Modal from './Modal';
import Markdown from './Markdown';
import PlayButton from './PlayButton';

const Container = styled(Grid)`
  padding: 64px 12px;
  display: flex;
  position: relative;
  width: 100%;

  perspective: 800px;
  align-items: flex-start;
`;

const StyledButtons = styled(CTAButtons)`
  padding: 0;
`;

const Description = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const Content = styled.div`
  ${columns(1, 12)};
  order: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;

  @media (min-width: 768px) {
    order: 0;
    ${columns(1, 6)}
  }
`;

const Media = styled.div`
  ${columns(1, 12)};
  order: 0;
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0;

  @media (min-width: 768px) {
    order: 1;
    ${columns(7, 6)}
  }
  @media (min-width: 1024px) {
    order: 1;
    ${columns(8, 5)}
  }
`;

type ContentProps = { $show: boolean; $fullScreen?: boolean };

const VideoContainer = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 1180px;
  width: 100%;
  opacity: 0;
  ${(props) =>
    props.$show &&
    css`
      animation: ${fadeTranslateIn(0, 48)} 1s ease-out;
      animation-fill-mode: both;
    `};

  pointer-events: all;

  & .videoContainer {
    display: flex;
    box-shadow: 0px 0px 1px ${COLORS.brand.regular.opacity(0.2)},
      0px 4px 100px ${COLORS.brand.regular.opacity(0.1)};
    border: 2px solid ${COLORS.brand.mid.css};
    border-radius: 6px;
    overflow: hidden;

    ${(props) => {
      if (props.$fullScreen) {
        return css`
          width: 100%;
          padding-top: 56.25%;
          position: relative;
          background: ${COLORS.black.css};
          & > div {
            top: 0;
            left: 0;
            position: absolute;
          }
        `;
      }
      return css``;
    }}
  }
`;

const Glows = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 3;
  mix-blend-mode: hard-light;
  & > div {
    position: absolute;
    z-index: 2;

    left: 0;
    top: 0;
    height: 2px;
    width: 25%;
    background: linear-gradient(
      to right,
      ${COLORS.brand.regular.opacity(0)} 0%,
      ${COLORS.brand.regular.opacity(1)} 90%,
      ${COLORS.brand.regular.opacity(0)} 100%
    );
  }
`;

const Close = styled.button`
  pointer-events: all;
  color: ${COLORS.white.css};
  margin-left: auto;
`;

const playerProps = (video: {
  url?: string | null;
  width?: number | string | null;
  height?: number | string | null;
}): ReactPlayerProps => ({
  width: video.width,
  height: video.height,
  url: video.url,
  muted: true,
  playsinline: true,
  loop: true,
  controls: false,
  className: 'videoContainer',
});

const Glow = () => {
  const style = useSpring({
    from: { opacity: 1, left: '0%' },
    to: { opacity: 0, left: '75%' },
    loop: true,
    delay: 1000,
    config: {
      duration: 1500,
      easing: easings.easeOutCubic,
    },
  });

  return <animated.div style={style} />;
};

type HomeSplashHeroProps = {
  data: BlockSplashVideoFragment;
};
function HomeSplashHero({ data }: HomeSplashHeroProps) {
  const [inViewRef, inView] = useInview({});

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  const fullVideo = data.fullVideoLink || data.fullVideo;

  return (
    <Container>
      <Content>
        <Heading
          heading={data.heading}
          byline={data.byline}
          show={inView}
          ref={inViewRef}
          align="left"
        />
        <Description>
          <Markdown content={data.description} />
        </Description>
        {!!data.callToActions?.items.length && (
          <StyledButtons
            links={data.callToActions?.items}
            byline={data.service}
          />
        )}
      </Content>
      <Media>
        {fullVideo && (
          <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
            <Close onClick={() => setOpen(false)}>
              <Icon name="X" width={24} height={24} />
            </Close>
            <VideoContainer $show $fullScreen={typeof fullVideo === 'string'}>
              <ReactPlayer
                {...playerProps(
                  typeof fullVideo === 'string'
                    ? { url: fullVideo, width: '100%' }
                    : fullVideo
                )}
                playing={typeof fullVideo === 'string'}
                controls
                muted={false}
              />
            </VideoContainer>
          </Modal>
        )}
        {data.video && (
          <VideoContainer $show={show}>
            <Glows>
              <Glow />
            </Glows>
            <PlayButton onClick={() => setOpen(true)} />
            <ReactPlayer
              {...playerProps(data.video)}
              playing={!open}
              onStart={() => {
                setShow(true);
              }}
            />
          </VideoContainer>
        )}
      </Media>
    </Container>
  );
}

export default memo(HomeSplashHero);
